import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import DangerouslySetHtmlContent from "../../../utilities/DangerouslySetHtmlContent";
import ErrorBlock from "../ErrorBlock";
import {isJson} from "../../../admin/spinup/utilities";
import {ErrorBoundary} from "../../../utilities/ErrorBoundary";
import {IBlockComponentProps} from "../_Block";

interface IHtmlBlockProps extends IBlockComponentProps {
    blockObj: IHtmlBlockObj,
}

export default function HtmlBlock(props: IHtmlBlockProps) {
    try {
        const {blockType} = props.blockObj;

        let json_data = props.blockObj.blockModel.jsonObj ? props.blockObj.blockModel.jsonObj : props.blockObj.blockModel.json_data;
        if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data);
        const settings = json_data.block?.settings || {};

        const {code, title} = settings;
        const hasTitle = !!title && title !== 'HTML';

        return <BlockScroller blockRef={props.blockRef}
                              blockObj={props.blockObj}
                              htmlId={props.htmlId}
                              setShowExpandButton={props.setShowExpandButton}
                              outsideGrid={props.outsideGrid}>
            {hasTitle && <BlockHead blockType={blockType} editableTitle={title} blockId={props.blockObj.id}/>}
            <ErrorBoundary userGenerated={true}>
                <DangerouslySetHtmlContent className="sb-blockContent sb-html-snippet-content" html={code}/>
            </ErrorBoundary>
        </BlockScroller>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
}

// HtmlBlock.propTypes = {
//     blockType: PropTypes.string,
//     title: PropTypes.string,
//     code: PropTypes.string,
// };
